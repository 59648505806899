import React from 'react'
import { isEmpty } from 'lodash'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { containerStyle } from '@atoms/Grid'
import Copy from './Copy'

const Copies = ({ data }) => {
  const { items } = data
  return (
    <>
      {!isEmpty(items) && (
        <S.Section>
          {items.map((item) => (
            <S.Content key={item.title}>
              <Copy data={item} />
            </S.Content>
          ))}
        </S.Section>
      )}
    </>
  )
}

export default Copies

const S = {}

S.Section = styled.sectionBox`
  ${containerStyle}
  ${breakpoints({
    xs: css`
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 50;
      margin-bottom: 50;
    `,
    md: css`
      grid-template-columns: 1fr 1fr;
      row-gap: 80;
      margin-bottom: 151;
    `,
  })}
`

S.Content = styled.divBox`
  ${breakpoints({
    xs: css`
      max-width: 100%;
    `,
    md: css`
      max-width: 486;
    `,
  })}
`
