import React from 'react'
import Markdown from 'react-markdown'
import styled, { x, css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { GatsbyImage } from "gatsby-plugin-image";

import HTMLContent from '@atoms/HTMLContent'
import { Container, Col, Row } from '@atoms/Grid'
import { ButtonLink } from '@atoms/Button'

const Hero = ({ data, ...props }) => {
  const { title, paragraphs, button, buttonLink, image, logo } = data
  return (
    <S.Wrapper {...props}>
      <S.Container>
        <Row>
          <Col col={{ xs: 1, lg: 1 / 2 }}>
            <S.Content>
              {logo && (
                <x.div pb="25px">
                  <GatsbyImage image={logo.childImageSharp.gatsbyImageData} />
                </x.div>
              )}

              <S.Title as="h1" dangerouslySetInnerHTML={{ __html: title }} />
              <S.Paragraphs>{paragraphs}</S.Paragraphs>
              <S.ButtonLink to={buttonLink} variant="dark">
                {button}
              </S.ButtonLink>
            </S.Content>
          </Col>
          <Col col={{ xs: 1, lg: 1 / 2 }}>
            {image && <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} />}
          </Col>
        </Row>
      </S.Container>
    </S.Wrapper>
  );
}

export default Hero

const S = {}

S.Wrapper = styled.section`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      padding-top: 40;
      padding-bottom: 50;
      width: 100%;
    `,
    md: css`
      padding-top: 130;
      flex-direction: row;
      padding-bottom: 140;
      align-items: flex-start;
    `,
  })}
`

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      margin-top: 120;
    `,
    md: css`
      flex-direction: row;
      align-items: flex-start;
    `,
  })}
`

S.Content = styled(HTMLContent)`
  ${breakpoints({
    xs: css`
      font-size: 18;
    `,
    md: css`
      font-size: 20;
    `,
    xl: css`
      /* margin-right: 102; */
    `,
  })}
`

S.Title = styled.div`
  font-weight: ${th('fontWeights.black')};
  ${breakpoints({
    xs: css`
      font-size: 32;
      margin-bottom: 15;
      line-height: 1.3;
      letter-spacing: -0.02em;
    `,
    md: css`
      font-size: 72;
      margin-bottom: 30;
      padding-right: 80;
      line-height: 1.1;
    `,
  })}
`

S.Paragraphs = styled(Markdown)`
  ${breakpoints({
    xs: css`
      margin-bottom: 30;
    `,
    md: css`
      margin-bottom: 31;
      font-size: 24;
    `,
  })}
`

S.ButtonLink = styled(ButtonLink)`
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    lg: css`
      margin-bottom: 0;
    `,
  })}
`
