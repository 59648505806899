import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { TitleContent, Text } from '@atoms/Typography'
import { Container } from '@atoms/Grid'

import transformMDtoHTML from '@utils/transformMDtoHTML'

const Copy = ({ data, ...props }) => {
  const { title, paragraphs } = data
  const __html = transformMDtoHTML(paragraphs)

  return (
    <S.Copy {...props}>
      <Container>
        {title && <S.Title as="h2">{title}</S.Title>}
        <S.Text as="div" dangerouslySetInnerHTML={{ __html }} />
      </Container>
    </S.Copy>
  )
}

export default Copy

const S = {}

S.Copy = styled.box`
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      margin-bottom: 151;
    `,
  })}
`

S.Title = styled(TitleContent)`
  ${breakpoints({
    xs: css`
      line-height: 1.5;
      margin-bottom: 10;
    `,
    md: css`
      line-height: 1.3;
      font-size: 36;
      margin-bottom: 30;
    `,
  })}
`

S.Text = styled(Text)`
  ${breakpoints({
    xs: css`
      font-size: 16;
    `,
    md: css`
      font-size: 20;
    `,
  })}
`
