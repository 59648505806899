import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import Layout from '@organisms/Layout'
import Hero from '@organisms/Landing/Hero'
import Copy from '@organisms/Landing/Copy'
import Copies from '@organisms/Landing/Copies'
import Cta from '@molecules/Cta'
import ProjectListGrid from '@molecules/ProjectListGrid'

import SectionImageCopy from '@molecules/SectionImageCopy'

import idgen from '@utils/idgen'

const Landing = ({ data, pageContext, ...props }) => {
  const { blocks, meta } = data.page.nodes[0].frontmatter
  return (
    <Layout
      hamburgerMenu
      title={meta.title}
      description={meta.description}
      buttonColor="dark"
    >
      <S.Main>
        {blocks.map((item) => {
          switch (item.type) {
            case 'hero':
              return <Hero data={item} key={idgen()} />
            case 'grid':
              return <Copies data={item} key={idgen()} />
            case 'copy':
              return <Copy data={item} key={idgen()} />
            case 'copyImage':
              return (
                <SectionImageCopy
                  key={idgen()}
                  marginBottom={{ xs: 50, md: 200 }}
                  data={item}
                />
              )
            case 'cta':
              return <Cta data={{ ...item }} key={idgen()} />
            case 'externalSection':
              return (
                <ProjectListGrid
                  sectionTitle={item.title}
                  data={data.projects.nodes}
                  filterKey="tags"
                  filterValues={[pageContext.slug]}
                  key={idgen()}
                />
              )
            default:
              return null
          }
        })}
      </S.Main>
    </Layout>
  )
}

export default Landing

const S = {}

S.Main = styled.mainBox`
  padding-bottom: 130;

  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
    `,
  })}
`

export const pageQuery = graphql`
  query landingPages($slug: String!) {
    page: allMarkdownRemark(filter: { frontmatter: { slug: { eq: $slug } } }) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { collection: { eq: "project" } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          excerpt
          tags
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          cardImage: coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 540
                height: 475
                transformOptions: { cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
