import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'

import ImageContent from '@molecules/ImageContent'
import { Container } from '@atoms/Grid'

const Section = ({ data }) => {
  return (
    <S.Section mb={{ xs: 50, md: 140 }}>
      <S.ImageContent
        marginBottom={{ xs: 50, md: 200 }}
        data={data}
        inverse={data.inverse}
      />
    </S.Section>
  )
}

export default Section

const S = {}

S.Section = styled(Container)``

S.ImageContent = styled(ImageContent)`
  ${breakpoints({
    xs: css`
      .gatsby-image-wrapper {
        height: auto;
      }
    `,
    md: css`
      .gatsby-image-wrapper {
        /* height: 354; */
      }

      :last-of-type {
        margin-bottom: 140;
      }
    `,
    lg: css`
      .gatsby-image-wrapper {
        /* height: 486; */
      }
    `,
  })}
`
